//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import wxHelper from './common/wxHelper'
import commonHelper from './common/common'
import api from './common/api'
import networking from './common/networking'
import { LocalStorage } from "quasar";
export default {
    name: "Detail",
    data() {
        return {
            imageHost: api.imageHost,
            fullscreenLoading: false,
            base: {
                matnr: '',
                price: '',
                apparel: '',
                scene: '',
                img: ''
            },
            common: {
                name: '',
                sellingPoints: [],
                image: ''
            },
            imageHeight: 484,
            item: null,
            activeName: 'baseInfo',
            tabPosition: 'basePoints',
            sellingPoints: [
            ],
            imgList: [],
            videoList: [],
            // { key: '面料', value: '100%纯羊毛面料，光泽柔和自然，手感非常地轻薄柔软，光泽柔和自然，手感非常地轻薄柔软。' },
            // { key: '款式', value: '贴身有型。贴身有型。贴身有型。贴身有型。贴身有型。贴身有型。贴身有型。贴身有型。贴身有型。' },
            // { key: '颜色', value: '100%纯羊毛面料，光泽柔和自然，手感非常地轻薄柔软，光泽柔和自然，手感非常地轻薄柔软。' }
            commonName: '松紧定位便西',
            commonDesc: '功能细节: 在功能性设计上的突破点在功能性设计上的突破点在功能性设计上的突破点在功能性设计上的突破点在功能性设计上的突破点',
            relateList: []

        }

    },
    methods: {
        goHome: function () {
            console.log(this.$router);
            console.log(this.$router.options.routes.length);
            // if (this.$router.options.routes.length == 3) {
            //     this.$router.go(-2);
            // } else {
            //     this.$router.go(-1);
            // }
            this.$router.replace('/shoppingguideproducts/index');
        },
        goList: function () {

        },
        getRelateList() {
            const params = [
                {
                    SIGN: "I",
                    OPTION: "EQ",
                    PARAMETER: "ZZMATSTR_CMOO_001",
                    LOW: this.item.matnr,//this.item.matnr
                    HIGH: ""
                }
            ]
            this.axios
                .post(
                    "/" +
                    this.$ajaxPrefix.consumer +
                    "/bw/BwApi/getjioncategory",
                    params
                )
                .then((res) => {
                
                    if (res && res.data && res.data.result) {
                        this.relateList=res.data.data.sort((a,b)=>{
                            return Number(b.orders_number)-Number(a.orders_number);
                        })
                    }

                });
        },
        handleScan: function () {
            wxHelper.scanQRCode((resultStr) => {
                console.log(resultStr);
                if (resultStr) {
                    this.fullscreenLoading = true;
                    networking.requestScanAPI(resultStr, (products) => {
                        if (products.length == 1) {
                            //this.$router.push({name: 'detail', params: products[0]});
                            this.item = products[0];
                            document.title = this.item.matnr;
                            this.requestDetail(this.item.matnr);
                        } else {
                            //this.goList();
                        }
                    }, () => {
                        this.fullscreenLoading = false;
                    })
                }
            })
        },
        handleClick: function () {
            console.log('handleClick');
        },
        requestDetail: function (matnr) {
            this.fullscreenLoading = true;
            this.axios.get(`${api.detail}?matnr=${matnr}`).then(response => {
                if (commonHelper.success(response)) {
                    let data = response.data.data;
                    if (data && data.length > 0) {
                        let item = data[0];
                        this.videoList = item.videoList;
                        this.imgList = item.imgList
                        for (let key in this.base) {
                            if (item[key]) {
                                if (key == 'img') {
                                    this.base[key] = api.imageHost + item[key];
                                } else {
                                    this.base[key] = item[key];
                                }
                            }
                        }
                        let usps = item.usps;
                        if (usps) {
                            let points = [];
                            for (let item of usps) {
                                for (let key in item) {
                                    if (key == '人无我有' && !item[key]) {
                                        continue;
                                    }
                                    points.push({ key: key, value: item[key] });
                                }
                            }
                            // console.log(points);
                            this.sellingPoints = points;
                        }
                        //通用卖点
                        /*
                        let currentInfo = item.current_info;
                        if (currentInfo && currentInfo.length > 0) {
                            this.common.name = currentInfo[0].title;
                            this.common.image = currentInfo[0].img;
                        }
                        let currentUsp = item.current_usp;
                        if (currentUsp) {
                            let points = [];
                            for (let item of currentUsp) {
                                console.log(item);
                                for (let key in item) {
                                    points.push({key: key, value: item[key]});
                                }
                            }
                            console.log(points);
                            this.common.sellingPoints = points;
                        }
                        */
                    }
                }
                console.log(response);
            }).catch(function (error) {
                // handle error
                console.log(error);
                alert(error);
            }).then(() => {
                this.fullscreenLoading = false;
            });
        },
        rebuildSrc(season,zdatnr){
            let seasonText=season.replace('SS','春夏').replace('FW','秋冬');
  
            console.log(`https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com/product/BXN/20${seasonText}/${zdatnr}.jpg`)
           return `https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com/product/BXN/20${seasonText}/${zdatnr}.jpg`
        }
    },
    mounted() {

        let productParams = LocalStorage.getItem('productParams');
        if (productParams) {
            let item = JSON.parse(productParams);
            this.item = item;
        }

        // alert(this.item.matnr)
        //this.item = this.$route.params;
        document.title = this.item.matnr;
        this.$nextTick(() => {
            this.imageHeight = Math.floor(this.$refs.imageDiv.clientWidth / 0.66);
        })
        console.log(this.item);
        this.requestDetail(this.item.matnr);
        this.getRelateList()
        networking.requestJsSdkConfig()
    }
}
